import { ContractTransaction, ethers } from 'ethers'

export type Web3ProviderState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  provider: any
  web3Provider: ethers.providers.Web3Provider | null | undefined
  address: string | null | undefined
  network: ethers.providers.Network | null | undefined
  connect: (() => Promise<void>) | null
  disconnect: (() => Promise<void>) | null
  mint: (() => Promise<ContractTransaction | undefined>) | null
  isPublicMint: boolean | null | undefined
  isWhitelistMint: boolean | null | undefined
  currentTokenId: number | null | undefined
  nftBalance: number | undefined
  whitelistClaimed: boolean | null | undefined
  switchToOptimism: (() => Promise<void>) | null
  loading: boolean
  fetchLatestStats: (() => Promise<void>) | null
}

export const web3InitialState: Web3ProviderState = {
  provider: null,
  web3Provider: null,
  address: null,
  network: null,
  connect: null,
  disconnect: null,
  mint: null,
  isPublicMint: null,
  isWhitelistMint: null,
  switchToOptimism: null,
  currentTokenId: null,
  nftBalance: 0,
  whitelistClaimed: null,
  loading: false,
  fetchLatestStats: null,
}

export type Web3Action =
  | {
      type: 'SET_WEB3_PROVIDER'
      provider?: Web3ProviderState['provider']
      web3Provider?: Web3ProviderState['web3Provider']
      address?: Web3ProviderState['address']
      network?: Web3ProviderState['network']
    }
  | {
      type: 'SET_ADDRESS'
      address?: Web3ProviderState['address']
    }
  | {
      type: 'SET_NETWORK'
      network?: Web3ProviderState['network']
    }
  | {
      type: 'RESET_WEB3_PROVIDER'
    }
  | {
      type: 'SET_MINT_STATUS'
      isPublicMint?: Web3ProviderState['isPublicMint']
      isWhitelistMint?: Web3ProviderState['isWhitelistMint']
      currentTokenId?: Web3ProviderState['currentTokenId']
      nftBalance?: Web3ProviderState['nftBalance']
      whitelistClaimed?: Web3ProviderState['whitelistClaimed']
    }
  | {
      type: 'SET_LOADING'
      loading?: Web3ProviderState['loading']
    }

export function web3Reducer(state: Web3ProviderState, action: Web3Action): Web3ProviderState {
  switch (action.type) {
    case 'SET_WEB3_PROVIDER':
      return {
        ...state,
        provider: action.provider,
        web3Provider: action.web3Provider,
        address: action.address,
        network: action.network,
      }
    case 'SET_MINT_STATUS':
      return {
        ...state,
        isPublicMint: action.isPublicMint,
        isWhitelistMint: action.isWhitelistMint,
        currentTokenId: action.currentTokenId,
        nftBalance: action.nftBalance,
        whitelistClaimed: action.whitelistClaimed,
      }
    case 'SET_ADDRESS':
      return {
        ...state,
        address: action.address,
      }
    case 'SET_NETWORK':
      return {
        ...state,
        network: action.network,
      }
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.loading || false,
      }
    case 'RESET_WEB3_PROVIDER':
      return web3InitialState
    default:
      throw new Error()
  }
}
