export const OptimismMainetConfig = {
  chainId: '0xa',
  rpcUrls: ['https://mainnet.optimism.io'],
  chainName: 'Optimism Mainnet',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  blockExplorerUrls: ['https://optimistic.etherscan.io/'],
}

export const OptimismKovanConfig = {
  chainId: '0x45',
  rpcUrls: ['https://kovan.optimism.io'],
  chainName: 'Optimism Kovan',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
  },
  blockExplorerUrls: ['https://kovan-optimistic.etherscan.io/'],
}
