import { useEffect, useReducer, useCallback } from 'react'
import { ContractTransaction, ethers } from 'ethers'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import NFTContractFactory from '../abis/ERC721CryptoTesters.json'

import { Web3ProviderState, Web3Action, web3InitialState, web3Reducer } from '../reducers'
import { OptimismKovanConfig, OptimismMainetConfig } from '../utils/networks'

import { MerkleTree } from 'merkletreejs'
import keccak256 from 'keccak256'

import whitelist from '../data/nft-whitelist.json'

const contractAddress = ethers.utils.getAddress(process.env.NEXT_PUBLIC_TESTERS_NFT_ADDRESS || '')

const networkConfig = OptimismMainetConfig

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: process.env.NEXT_PUBLIC_INFURA_ID,
      rpc: {
        10: 'https://optimism-mainnet.infura.io/v3/' + process.env.NEXT_PUBLIC_INFURA_ID,
        69: 'https://optimism-kovan.infura.io/v3/' + process.env.NEXT_PUBLIC_INFURA_ID,
      },
    },
  },
  coinbasewallet: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: 'Cryptotesters NFT Mint', // Required
      infuraId: process.env.NEXT_PUBLIC_INFURA_ID, // Required
      rpc: '', // Optional if `infuraId` is provided; otherwise it's required
      chainId: 10, // Optional. It defaults to 1 if not provided
      darkMode: true, // Optional. Use dark theme, defaults to false
    },
  },
}

let web3Modal: Web3Modal | null
if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: 'mainnet',
    cacheProvider: true,
    providerOptions, // required
    theme: 'dark', // optional not required
  })
}

export const useWeb3 = (): Web3ProviderState => {
  const [state, dispatch] = useReducer(web3Reducer, web3InitialState)
  const {
    provider,
    web3Provider,
    address,
    network,
    isPublicMint,
    isWhitelistMint,
    currentTokenId,
    nftBalance,
    whitelistClaimed,
    loading,
  } = state

  const addressIsWhitelisted = () => {
    if (address)
      return (
        whitelist.includes(address) ||
        whitelist.includes(ethers.utils.getAddress(address)) ||
        whitelist.includes(address.toLowerCase()) ||
        whitelist.includes(address.toUpperCase())
      )
    return false
  }

  const connect = useCallback(async () => {
    if (web3Modal) {
      try {
        const provider = await web3Modal.connect()
        const web3Provider = new ethers.providers.Web3Provider(provider)
        const signer = web3Provider.getSigner()
        const address = await signer.getAddress()
        const network = await web3Provider.getNetwork()
        dispatch({
          type: 'SET_WEB3_PROVIDER',
          provider,
          web3Provider,
          address,
          network,
        } as Web3Action)
        dispatch({ type: 'SET_LOADING', loading: true })

        // get mint status, after connecting
        if ([10].includes(network.chainId) && contractAddress) {
          const contract = new ethers.Contract(
            contractAddress,
            NFTContractFactory.abi,
            web3Provider,
          )
          const isPublicMint = await contract.publicMint()
          const isWhitelistMint = await contract.whitelistMint()
          const whitelistClaimed = await contract.whitelistClaimed(address)
          let currentTokenId = await contract.currentTokenId()
          currentTokenId = ethers.utils.formatUnits(currentTokenId, 0)
          let nftBalance = await contract.balanceOf(address)
          nftBalance = ethers.utils.formatUnits(nftBalance, 0)
          dispatch({
            type: 'SET_MINT_STATUS',
            isPublicMint,
            isWhitelistMint,
            currentTokenId,
            nftBalance,
            whitelistClaimed,
          } as Web3Action)
        }

        dispatch({ type: 'SET_LOADING', loading: false })
      } catch (e) {
        console.log('connect error', e)
      }
    } else {
      console.error('No Web3Modal')
    }
  }, [])

  const disconnect = useCallback(async () => {
    if (web3Modal) {
      web3Modal.clearCachedProvider()
      if (provider?.disconnect && typeof provider.disconnect === 'function') {
        await provider.disconnect()
      }
      console.log('Disconnected from Web3')
      dispatch({
        type: 'RESET_WEB3_PROVIDER',
      } as Web3Action)
    } else {
      console.error('No Web3Modal')
    }
  }, [provider])

  const switchToOptimism = useCallback(async () => {
    if (web3Modal) {
      if (provider) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [networkConfig],
          })
        } catch (e) {
          console.log(e)
        }
      }
    }
  }, [provider])

  const mint = useCallback(async (): Promise<ContractTransaction | undefined> => {
    if (web3Modal) {
      try {
        if (web3Provider && address) {
          const signer = web3Provider.getSigner()
          const contract = new ethers.Contract(contractAddress, NFTContractFactory.abi, signer)
          if (addressIsWhitelisted() && !whitelistClaimed && !isPublicMint) {
            const leaves = whitelist.map((address) => keccak256(address))
            const tree = new MerkleTree(leaves, keccak256, { sortPairs: true })
            const leaf = keccak256(address)
            const proof = tree.getHexProof(leaf)
            return contract.whitelistClaim(proof)
          } else {
            const options = { value: ethers.utils.parseEther('0.3') }
            return contract.publicMintTo(address, options)
          }
        }
      } catch (e) {
        console.log('mint error')
        console.error(e)
      }
    }
  }, [web3Provider, address, nftBalance])

  const fetchLatestStats = useCallback(async () => {
    if (network && web3Provider) {
      try {
        // get mint status, after connecting
        if ([10].includes(network.chainId) && contractAddress) {
          const contract = new ethers.Contract(
            contractAddress,
            NFTContractFactory.abi,
            web3Provider,
          )
          const isPublicMint = await contract.publicMint()
          const isWhitelistMint = await contract.whitelistMint()
          const whitelistClaimed = await contract.whitelistClaimed(address)
          let currentTokenId = await contract.currentTokenId()
          currentTokenId = ethers.utils.formatUnits(currentTokenId, 0)
          let nftBalance = await contract.balanceOf(address)
          nftBalance = ethers.utils.formatUnits(nftBalance, 0)
          dispatch({
            type: 'SET_MINT_STATUS',
            isPublicMint,
            isWhitelistMint,
            currentTokenId,
            nftBalance,
            whitelistClaimed,
          } as Web3Action)
          if (loading) {
            dispatch({ type: 'SET_LOADING', loading: false })
          }
        }
      } catch (e) {
        console.log('connect error', e)
      }
    }
  }, [network, web3Provider, contractAddress])

  // Auto connect to the cached provider and fetch latest stats every minute
  useEffect(() => {
    if (web3Modal && web3Modal.cachedProvider) {
      connect()
      const interval = setInterval(fetchLatestStats, 60 * 1000)
      return () => clearInterval(interval)
    }
  }, [connect])

  // EIP-1193 events
  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts: string[]) => {
        console.log('Changed Web3 Account')
        dispatch({
          type: 'SET_ADDRESS',
          address: accounts[0],
        } as Web3Action)
        window.location.reload()
      }

      // https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
      const handleChainChanged = (_hexChainId: string) => {
        if (typeof window !== 'undefined') {
          console.log('switched to chain...', _hexChainId)
          window.location.reload()
        } else {
          console.log('window is undefined')
        }
      }

      const handleDisconnect = (error: { code: number; message: string }) => {
        // eslint-disable-next-line no-console
        console.log('disconnect', error)
        // disconnect()
      }

      provider.on('accountsChanged', handleAccountsChanged)
      provider.on('chainChanged', handleChainChanged)
      provider.on('disconnect', handleDisconnect)

      // Subscription Cleanup
      return () => {
        if (provider.removeListener) {
          provider.removeListener('accountsChanged', handleAccountsChanged)
          provider.removeListener('chainChanged', handleChainChanged)
          provider.removeListener('disconnect', handleDisconnect)
        }
      }
    }
  }, [provider, disconnect, web3Provider])

  return {
    provider,
    web3Provider,
    address,
    network,
    connect,
    disconnect,
    mint,
    switchToOptimism,
    isPublicMint,
    isWhitelistMint,
    currentTokenId,
    nftBalance,
    whitelistClaimed,
    loading,
    fetchLatestStats,
  } as Web3ProviderState
}
